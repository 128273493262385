import { useState, useContext } from 'react'
import { Drawer, Divider } from 'antd'
import { TiThMenu } from "react-icons/ti"
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../App'

export function NavMenu() {

      const navigate = useNavigate()

      const { UserData } = useContext(MyContext)

      const [ open, setOpen ] = useState(false)
      const showDrawer = () => {
            setOpen(true)
      }
      const onClose = () => {
            setOpen(false)
      }

      return (

            <>
                  <TiThMenu className="clickable" onClick={showDrawer} style={{ color: 'rgba(0,0,0,0.6)', fontSize: '30px', marginTop: '10px' }} />

                  <Drawer className="nav-menu-drawer" title="MENU" placement="right" onClose={onClose} open={open}>

                        <div className="menu-link">

                              {/* Show to all */}
                              {UserData.accessLevel >= 0
                                    ? <>
                                          <p className="navgroup_title">Customers</p>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/customers')}> - Active</p>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/customers/incomplete')}> - Incomplete</p>

                                          <Divider />
                                          <p className="navgroup_title">Compliance & Regulatory</p>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/acturis')}>- Acturis Log</p>

                                          <Divider />
                                          <p className="navgroup_title">Accounts & Financial</p>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/accounting/reconciliation')}>- IBA & Payment Reconciliation</p>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/accounting/opayo')}>- Opayo Reconciliation</p>
                                    </>
                                    : null}
                              {/* Show to admin */}
                              {UserData.accessLevel >= 4
                                    ? <>
                                          <p className="navgroup_link" onClick={() => navigate('/reports/sales/performance-analysis')}>- Performance Analysis</p>
                                    </>
                                    : null}
                        </div>

                  </Drawer >
            </>

      )

}