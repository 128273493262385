import { Row, Col, Skeleton, Modal, Tag } from "antd"
import { Header } from "../../Header"
import { Table } from "../../tables/Table"
import { useEffect, useState, useContext } from "react"
import dayjs from "dayjs"
import axios from "axios"
import { MyContext } from "../../../App"
import { defaultTableConfig } from "../../../libs/config"
import { BatchDetail } from "../../BatchDetail"
import { get, ucFirst } from "../../../libs/helper"

export function OpayoReconciliation() {

      const { AppData } = useContext(MyContext)

      const [ policyData, setPolicyData ] = useState([])

      const [ startDate ] = useState(dayjs().subtract(27, 'd').format("YYYY-MM-DD"))
      const [ endDate ] = useState(dayjs().format("YYYY-MM-DD"))
      const [ loading, setLoading ] = useState(true)

      const [ errorCount, setErrorCount ] = useState(0)
      const [ mtaData, setMtaData ] = useState([])

      const [ fqData, setFqData ] = useState([])
      const [ bgData, setBgData ] = useState([])

      const [ batchLoading, setBatchLoading ] = useState(true)
      const [ batchID, setBatchID ] = useState('')
      const [ batchBrand, setBatchBrand ] = useState('')
      const [ batchDate, setBatchDate ] = useState('')
      const [ batchDetail, setBatchDetail ] = useState([])

      useEffect(() => {

            (async () => {

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "policies",
                        query: {
                              select: "opayo_ref, acturis_contact_ref",
                              where: { [ `opayo_ref IS NOT NULL` ]: "", [ `payment_completed >= '${dayjs().subtract(15, 'd').format("YYYY-MM-DD HH:mm:ss")}'` ]: "" }
                        }
                  }

                  // Request data
                  const { result } = await get(params, AppData)
                  setPolicyData(result)

            })()

      }, [ AppData ])

      useEffect(() => {

            (async () => {

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "mtas",
                        query: {
                              select: "mtas.policy_id, mtas.payment_ref, policies.policy_number, policies.acturis_contact_ref",
                              where: { [ `payment_ref IS NOT NULL` ]: "" },
                              join: { table: 'policies', condition: 'mtas.policy_id = policies.id' },
                              order: { "mtas.created": "DESC" }
                        }
                  }

                  // Request data
                  const { result } = await get(params, AppData)
                  setMtaData(result)

            })()

      }, [ AppData ])

      useEffect(() => {

            (async () => {
                  setLoading(true)
                  const { data } = await axios.post(`${AppData.api.base}/opayo/batch/list`, { start: startDate, end: endDate })
                  setFqData(data.result.fastquote)
                  setBgData(data.result.boxguard)
                  setLoading(false)
            })()

            // eslint-disable-next-line
      }, [])

      const [ isModalOpen, setIsModalOpen ] = useState(false)
      const handleOk = () => {
            setIsModalOpen(false)
      }
      const handleCancel = () => {
            setErrorCount(0)
            setBatchDetail([])
            setIsModalOpen(false)
            setBatchLoading(true)
      }

      const columns = [
            {
                  name: 'Date',
                  key: 'date'
            },
            {
                  name: 'Batch ID',
                  key: 'batch_id'
            },
            {
                  name: 'Value',
                  key: 'value'
            }
      ]

      const customTableConfig = {
            key: 'batch_id',
            columns,
            search: {
                  active: false
            },
            export: {
                  active: false
            },
            datepicker: {
                  period: 6,
                  period_type: 'd'
            },
            pagination: {
                  defaultPageSize: 7,
                  showSizeChanger: true,
                  pageSizeOptions: [ '7', '14', '28' ]
            },
            onRow: {
                  active: true,
                  func: function (obj) {
                        getBatch(obj)
                  }

            }
      }

      async function getBatch(obj) {

            setBatchLoading(true)
            setIsModalOpen(true)
            setBatchID(obj.batch_id)
            setBatchDate(obj.date)
            setBatchBrand(ucFirst(obj.brand))
            const { data } = await axios.post(`${AppData.api.base}/opayo/batch`, { brand: obj.brand, id: obj.batch_id })

            // Create new empty array
            const DATA = []
            let err = 0

            // Format some data 
            for (let d of data.result.vspaccess.transactions.transaction) {

                  // let type = d.systemused === "T" || d.transactiontype === "Refund" ? "manual" : "system"
                  let amount = d.transactiontype === "Refund" ? <span style={{ color: "red" }}>-{d.amount}</span> : d.amount

                  let type = d.transactiontype === "Refund"
                        ? <Tag color="purple">REFUND</Tag>
                        : d.systemused === "T"
                              ? <Tag color="blue">MTA</Tag>
                              : <Tag color="green">ONLINE</Tag>

                  // Try and find Acturis contact references
                  let acr

                  // First, find system payments
                  if (d.transactiontype === "Payment" && d.systemused !== "T") {
                        acr = policyData.find(item => item.opayo_ref === d.vendortxcode)
                        if (acr === undefined) {
                              acr = <Tag color="red">MISPOST</Tag>
                              err++
                        }

                  }

                  // Then, find manual terminal payments (mtas)
                  else if (d.transactiontype === "Payment" && d.systemused === "T") {
                        acr = mtaData.find(item => item.payment_ref === d.vendortxcode)
                        if (acr === undefined) {
                              acr = <Tag color="red">{d.vendortxcode}</Tag>
                              err++
                        }
                  }

                  // Then, find manual terminal refunds (cancellations)
                  else if (d.transactiontype === "Refund") {

                        // This finds refunds of terminal payments
                        if (d.vendortxcode.includes("Tboxg") || d.vendortxcode.includes("Tfast")) {

                              // Opayo prefix "REF" && suffix "-123", strip and find original
                              let vtxc = d.vendortxcode
                              let vtxc2 = vtxc.slice(3)
                              let vtxc3 = vtxc2.substring(0, vtxc2.lastIndexOf("-"))
                              console.log(vtxc3)
                              acr = mtaData.find(item => item.payment_ref === d.vendortxcode)

                              if (acr === undefined) {
                                    acr = <Tag color="red">{d.vendortxcode}</Tag>
                                    err++
                              }
                        }

                        //
                        else {

                              // Opayo prefix "REF" && suffix "-123", strip and find original
                              let vtxc = d.vendortxcode
                              let vtxc2 = vtxc.slice(3)
                              let vtxc3 = vtxc2.substring(0, vtxc2.lastIndexOf("-"))
                              acr = policyData.find(item => item.opayo_ref === vtxc3)

                              if (acr === undefined) {
                                    acr = <Tag color="red">MISPOST</Tag>
                                    err++
                              }

                        }
                  }

                  else {
                        acr = '-'
                  }

                  let acturis = acr?.acturis_contact_ref ? acr.acturis_contact_ref : acr

                  DATA.push({
                        type,
                        started: dayjs(d.started, "DD/MM/YYYY HH:mm:ss.SSS").format("HH:mm"),
                        cardholder: d.cardholder.toUpperCase(),
                        amount,
                        acturis,
                  })

            }

            setBatchDetail(DATA)
            setBatchLoading(false)
            setErrorCount(err)

      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">

                                          <Col>
                                                <h1>Report : Opayo Reconciliation</h1>
                                                <p>Designed to help Mihaela match up Opayo transactions</p>
                                          </Col>

                                    </Row>

                                    <div className="card" >

                                          <h2>Fastquote</h2>

                                          {loading
                                                ? <Skeleton active />
                                                : <Table
                                                      className="clickable"
                                                      data={fqData}
                                                      config={{ ...defaultTableConfig, ...customTableConfig }}
                                                />
                                          }

                                    </div>

                                    <div className="card" >

                                          <h2>Boxguard</h2>

                                          {loading
                                                ? <Skeleton active />
                                                : <Table
                                                      className="clickable"
                                                      data={bgData}
                                                      config={{ ...defaultTableConfig, ...customTableConfig }}
                                                />
                                          }

                                    </div>

                                    <Modal width={window.innerWidth > 1000 ? 1000 : window.innerWidth * 0.9} title={batchLoading ? 'loading ..' : `${batchBrand} - ${batchDate} - Batch ID: ${batchID} - ${errorCount} Error${errorCount === 1 ? "" : "s"}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                          <div style={{ margin: "50px 0" }}>
                                                <BatchDetail data={batchDetail} />
                                          </div>
                                    </Modal>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
