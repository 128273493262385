import { MyContext } from '../../App'
import { useState, useEffect, useContext } from 'react'
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Row, Col, message, Tabs, Tag, Divider, Typography, Alert, Button } from "antd"
import { update, round, unescapeData, get } from "../../libs/helper"
import { TiArrowBackOutline } from "react-icons/ti"
import { Header } from "../Header"
import { EventTimeline } from "../EventTimeline"
import { Documents } from "../Documents"
import { Loading } from "../Loading"
import { Canx } from "../Canx"
import { Mta } from "../Mta"
import dayjs from "dayjs"
const { Text } = Typography

export function View() {

      const { AppData, UserData } = useContext(MyContext)

      const [ searchParams ] = useSearchParams()
      const policy_number = searchParams.get("id")

      const navigate = useNavigate()

      let [ data, setData ] = useState({})
      let [ isLoading, setIsLoading ] = useState(true)

      let [ activeTab, setActiveTab ] = useState("1")

      const [ tel, setTel ] = useState('')
      const [ email, setEmail ] = useState('')

      useEffect(() => {

            (async () => { await getPolicy() })()

            // eslint-disable-next-line
      }, [ policy_number ])

      const getPolicy = async () => {

            const params = {
                  brand: policy_number.includes("FQ") ? "fastquote" : "boxguard",
                  table: "policies",
                  query: { where: { policy_number } }
            }

            const { status, result } = await get(params, AppData)

            if (status === "success") {
                  setActiveTab('1')
                  setData(await unescapeData(result[ 0 ]))
                  setTel(result[ 0 ].telephone)
                  setEmail(result[ 0 ].email)
                  setIsLoading(false)

            } else {
                  message.error(result)
            }

      }

      const updatePolicy = async () => {
            (async () => {
                  await getPolicy()
            })()
      }

      async function updateContact(value, type) {

            // Check values have changed
            if (type === "e" && value === email) { return }
            if (type === "t" && value === tel) { return }

            const brand = data.policy_number.includes("FQ") ? "fastquote" : "boxguard"

            const field = type === "t" ? "telephone" : "email"

            const d = {
                  brand,
                  table: "policies",
                  data: {
                        where: { policy_number: data.policy_number },
                        data: { [ field ]: value }
                  }
            }

            let result = await update(d, AppData)

            // Handle response
            if (result.status === "success") {
                  message.success(result.result)
                  type === "e" ? setEmail(value) : setTel(value)
            }

            else { message.error(result.result) }

      }


      let summary = <>
            <h2>Cover information</h2>
            {data?.driver_type && data.driver_type.toLowerCase() === "c" ? <Tag>COURIER</Tag> : <Tag>REMOVER</Tag>}
            <Tag>GIT £{data.git}k</Tag><Tag>{data.vehicles > 1 ? `${data.vehicles} VEHICLES` : "1 VEHICLE"}</Tag><Tag>PL {data.pl > 0 ? `£${data.pl}m` : "-"}</Tag><Tag>EL {data.el === "10" ? "£10m" : "-"}</Tag><Tag>EU {data.europe > 0 ? "Y" : "-"}</Tag>
            <Divider />
            <h2>Billing</h2>
            <p>Opayo ref: {data.opayo_ref}</p>
            <p>Start Date: {dayjs(data.start_date).format("DD MMM YYYY")}</p>
            <p>Payment Type: {data?.payment_type && (data.payment_type === "dd" || data.payment_type === "M") ? <Tag>CBPF</Tag> : <Tag>PAID</Tag>}</p>
            <p>Net Premium (exc IPT): £{data.net_premium}</p>
            <p>IPT: £{data.ipt}</p>
            <p>Net Premium (inc IPT): £{round(parseFloat(data.net_premium) + parseFloat(data.ipt), 2)}</p>
            <p>Fee: £{data.fee}</p>
            <p>Gross Premium: £{data.gross_premium}</p>
            <Divider />
            <h2>Contact information</h2>
            <p>Primary: {data.title} {data.first_name} {data.last_name}</p>
            <p>DOB: {dayjs(data.dob).format("DD MMM YYYY")}</p>
            <p>Telephone: <Text editable={{ onChange: (e) => updateContact(e, 't') }}>{tel}</Text></p>
            <p>Email: <Text editable={{ onChange: (e) => updateContact(e, 'e') }}>{email}</Text></p>
            <p>Address: {data.address_1}, {data.town}, {data.county}, {data.postcode}</p>
            <Divider />
            <h2>Acturis Record</h2>
            <p>Contact ref: {data.acturis_contact_ref}</p>
            <p>Version ref: {data.acturis_version_ref}</p>
            {UserData.accessLevel >= 4 &&
                  <>
                        <Divider />
                        <h2>My.Dasher (aka Hazel)</h2>

                        <a href={`https://my.${policy_number.includes("FQ") ? "fastquote.co.uk" : "boxguard.uk"}/backdoor?user=${UserData.id}&id=${data.id}`} target="_blank" rel="noopener noreferrer">
                              <Button
                                    style={{ marginTop: 0 }}
                                    type="primary"
                                    size="small"
                              >{`Log into ${data.first_name}'s account`}
                              </Button>
                        </a>
                  </>
            }

      </>

      let legal_entity
      if (data?.policy_number) {
            if (data.policy_number.includes("BG")) {
                  switch (data.legal_entity) {
                        case "LC": legal_entity = "Limited Company"; break
                        case "ST": legal_entity = "Sole Trader"; break
                        case "LL": legal_entity = "Limited Liability Partnership"; break
                        case "PA": legal_entity = "Partnership"; break
                        default: legal_entity = "Unknown"; break
                  }
            }
            else {
                  switch (data.legal_entity) {
                        case "Limited Company": legal_entity = "Limited Company"; break
                        case "Sole Trader": legal_entity = "Sole Trader"; break
                        case "Limited Liability Partnership (LLC)": legal_entity = "Limited Liability Partnership"; break
                        case "Partnership": legal_entity = "Partnership"; break
                        default: legal_entity = "Unknown"; break
                  }
            }
      }

      let mta = data.cancelled === null
            ? UserData.accessLevel >= 2
                  ? <Mta updatePolicy={updatePolicy} data={data} entity={legal_entity} />
                  : 'Your user role permissions cannot perform this action'
            : 'This policy has been cancelled'

      // let mta = <Mta updatePolicy={updatePolicy} data={data} entity={legal_entity} />

      let canx = data.cancelled === null
            ? UserData.accessLevel >= 3
                  ? <Canx updatePolicy={updatePolicy} data={data} />
                  : 'Your user role permissions cannot perform this action'
            : 'This policy has been cancelled'

      let items = [
            {
                  label: `Summary`,
                  key: '1',
                  children: summary,
            },
            {
                  label: `Events`,
                  key: '2',
                  children: <EventTimeline id={policy_number} />
            },
            {
                  label: `Documents`,
                  key: '3',
                  children: <Documents id={policy_number} />
            },
            {
                  label: `Edit`,
                  key: '4',
                  children: mta
            },
            {
                  label: `Cancel`,
                  key: '5',
                  children: canx,
            }
      ]

      const body = isLoading ? <Loading /> : <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={22}>

                                    <Row className="pagetitle">
                                          <Col>
                                                <TiArrowBackOutline className="clickable" style={{ fontSize: '2rem', opacity: 0.3, marginBottom: '15px' }} onClick={() => navigate('/')} />
                                                <h1>{data.policy_number}</h1>
                                                <p>{data.legal_entity_name} <Tag>{legal_entity}</Tag></p>
                                          </Col>
                                    </Row>

                                    <div className="card">

                                          {data?.cancelled && data.cancelled !== null ? <><Row style={{ width: "100%", marginBottom: '25px' }}><Alert message="This policy has been cancelled" type="error" showIcon /></Row></> : null}
                                          <Row><Col span={24}>{body}</Col></Row>

                                    </div>

                              </Col>

                        </Row>

                  </div>
            </>



      )


}
