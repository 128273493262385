import React from 'react'
import ReactDOM from 'react-dom/client'
import 'antd/dist/reset.css'
import './index.css'
import App from './App'
import { AwsRum } from 'aws-rum-web'

try {
      const config = {
            sessionSampleRate: 1,
            guestRoleArn: "arn:aws:iam::731016139481:role/RUM-Monitor-eu-west-2-731016139481-0323279948961-Unauth",
            identityPoolId: "eu-west-2:5522f8d1-43c5-4ef0-b792-31fc84bf03e2",
            endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
            telemetries: [ "performance", "errors", "http" ],
            allowCookies: true,
            enableXRay: false
      }

      const APPLICATION_ID = '05d9d1ca-e5bf-48b9-8799-d20f2de2614a'
      const APPLICATION_VERSION = '1.0.0'
      const APPLICATION_REGION = 'eu-west-2'

      // eslint-disable-next-line
      const awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
      )
} catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
      <App />
)
