import { useNavigate } from "react-router-dom"
import { useState, useContext, useEffect } from 'react'
import { MyContext } from '../../App'
import { Row, Col, Button, Form, Input, message } from "antd"
import { TiFlashOutline } from "react-icons/ti"
import { get } from "../../libs/helper"
import { userConfig } from "../../libs/config"
import dayjs from "dayjs"

const userLoggedIn = localStorage.getItem("_dgxUser") !== null ? true : false

export function Login() {

      const { AppData, setAppData } = useContext(MyContext)
      const navigate = useNavigate()

      const { setUserData } = useContext(MyContext)

      const [ loading, setLoading ] = useState(false)
      const [ btnText, setBtnText ] = useState('Sign in')

      // If userLoggedIn, bypass form
      useEffect(() => {

            (async () => {

                  if (userLoggedIn) {

                        const params = {
                              brand: "dasher",
                              table: "users",
                              query: {
                                    select: `*`,
                                    where: { id: localStorage.getItem("_dgxUser"), status: 1 }
                              }
                        }

                        const { status, result } = await get(params, AppData)

                        if ((status === "success" && result.length > 0)) {

                              setUserData({ loggedIn: true, accessLevel: userConfig[ result[ 0 ].role ].level })
                              setUserData(result[ 0 ])

                              // Get rest of users for lookups
                              const params = {
                                    brand: "dasher",
                                    table: "users",
                                    query: {
                                          select: `id,first_name, last_name`
                                    }
                              }

                              const { result: users } = await get(params, AppData)
                              setAppData({ users: users })

                              navigate("/")

                        }

                        else {
                              message.error("Something went wrong. Please try again.")
                              setUserData({ loggedIn: false })
                        }

                  }

            })()

      }, [ userLoggedIn ])

      async function onFinish(values) {

            setLoading(true)
            setBtnText('Please wait')

            const params = {
                  brand: "dasher",
                  table: "users",
                  query: {
                        select: `*`,
                        where: { username: values.email, password: values.password, status: 1 }
                  }
            }

            const { status, result } = await get(params, AppData)

            if ((status === "success" && result.length > 0)) {

                  localStorage.setItem("_dgxUser", result[ 0 ].id)
                  setUserData({ loggedIn: true, accessLevel: userConfig[ result[ 0 ].role ].level })
                  setUserData(result[ 0 ])

                  // Get rest of users for lookups
                  const params = {
                        brand: "dasher",
                        table: "users",
                        query: {
                              select: `id,first_name, last_name`
                        }
                  }

                  const { result: users } = await get(params, AppData)
                  setAppData({ users: users })

                  navigate("/")

            }

            else {
                  message.error("Something went wrong. Please try again.")
                  setLoading(false)
                  setBtnText('Sign in')
            }

      }

      const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo)
            message.error('The form contains an error. Please fix it!')
      }


      return (
            <>
                  <Row justify="center">
                        <Col xs={22} md={8} style={{ textAlign: 'center' }}>
                              <TiFlashOutline style={{ color: '#E41882', fontSize: '90px', marginTop: '100px' }} />
                              <Row justify="center" style={{ marginTop: '25px', padding: '75px 0 50px 0', background: 'rgba(0,0,0,0.01)' }}>
                                    <Col xs={22} lg={16}>
                                          <Form
                                                name="login"
                                                layout="vertical"
                                                initialValues={{ remember: true }}
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                          >
                                                <Form.Item
                                                      label="Email"
                                                      name="email"
                                                      rules={[
                                                            { required: true, message: 'Please input your email!' }
                                                      ]}
                                                >
                                                      <Input />
                                                </Form.Item>

                                                <Form.Item
                                                      label="Password"
                                                      name="password"
                                                      rules={[
                                                            { required: true, message: 'Please input your password!' }
                                                      ]}
                                                >
                                                      <Input.Password />
                                                </Form.Item>

                                                <Form.Item>
                                                      <Button type="primary" htmlType="submit" loading={loading}>
                                                            {btnText}
                                                      </Button>
                                                </Form.Item>
                                                <p style={{ marginTop: "50px" }}>{AppData.version}</p>
                                          </Form>
                                    </Col>
                              </Row>

                        </Col>

                  </Row>

            </>
      )


}
